import { useEffect } from 'react';
import { sessionStorage } from 'react-storage';
import { useRouter } from '@src/routes';
import { useIntl } from 'react-intl';
import messages from '@utils/messages';
export var initAlertBarMessage = {
  open: false,
  message: '',
  severity: 'success'
};
var alertBarMessages = {
  inviteCoworker: {
    severity: 'success',
    key: 'INVITE_COWORKER_SUCCEED_MESSAGE'
  }
};
export var useAlertBarOnRouteChange = function useAlertBarOnRouteChange(alertBar, setAlertBar) {
  var router = useRouter();
  var intl = useIntl();
  useEffect(function () {
    var _sessionStorage$getIt;

    var showAlertBar = (_sessionStorage$getIt = sessionStorage.getItem('showAlertBar')) !== null && _sessionStorage$getIt !== void 0 ? _sessionStorage$getIt : '';
    var previousPage = sessionStorage.getItem('previousPage');
    var alertBarMessage = showAlertBar && showAlertBar in alertBarMessages ? alertBarMessages[showAlertBar] : ''; // the bar will be shown from previous page

    if (!alertBar.open && alertBarMessage) {
      setAlertBar({
        open: true,
        message: intl.formatMessage(messages[alertBarMessage.key]),
        severity: alertBar.severity
      });
    } // the bar will be disappear when navigate to another page


    if (alertBar.open && previousPage !== router.pathname) {
      setAlertBar(initAlertBarMessage);
      sessionStorage.setItem('showAlertBar', '');
    }
  }, [alertBar]);
};
export var useGlobalAlertBar = function useGlobalAlertBar() {
  var router = useRouter();

  var setAlertMessage = function setAlertMessage(alertBarKey) {
    sessionStorage.setItem('showAlertBar', alertBarKey);
  };

  var setAlertBarPreviousPage = function setAlertBarPreviousPage() {
    sessionStorage.setItem('previousPage', router.pathname);
  };

  var resetAlertBar = function resetAlertBar(setAlertBar) {
    setAlertBar(initAlertBarMessage);
    sessionStorage.setItem('showAlertBar', '');
  };

  var setInviteCoworkerAlertBar = function setInviteCoworkerAlertBar() {
    return setAlertMessage('inviteCoworker');
  };

  return {
    setInviteCoworkerAlertBar: setInviteCoworkerAlertBar,
    resetAlertBar: resetAlertBar,
    setAlertBarPreviousPage: setAlertBarPreviousPage
  };
};